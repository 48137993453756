import React, { useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

// Components
import { Share } from '../components/Share';

// Routes
import { About } from '../pages/About';
import { Admin } from '../pages/Admin';
import { Exhibitions } from '../pages/Exhibitions';
import { Home } from '../pages/Home';
import { NFT } from '../pages/NFT';
import { VIDEOS } from '../pages/Videos';
import { Works } from '../pages/Works';
import { ContactPage } from '../pages/ContactPage';
import { PrivacyPolicy } from '../pages/PrivacyPolicy';
import { CookiePolicy } from '../pages/CookiePolicy';

// Services
import { AuthContextProvider } from '../context/AuthContext';
import { LangContextProvider } from '../context/LangContext';

// Read JSON Data
const geninfo = require('../data/geninfo.json').data;

export const AppRouter = () => {
	// Open states
	const [openShare, setOpenShare] = useState(false);

	return (
		<BrowserRouter>
			<AuthContextProvider>
				<LangContextProvider>
					{openShare && (
						<Share setOpenShare={setOpenShare} url={geninfo[0].webpage} />
					)}

					<Routes>
						<Route path="/" element={<Home setOpenShare={setOpenShare} />} />
						<Route
							path="/about"
							element={<About setOpenShare={setOpenShare} />}
						/>
						<Route
							path="/exhibitions"
							element={<Exhibitions setOpenShare={setOpenShare} />}
						/>
						<Route path="/nft" element={<NFT setOpenShare={setOpenShare} />} />
						<Route
							path="/works"
							element={<Works setOpenShare={setOpenShare} />}
						/>
						<Route
							path="/videos"
							element={<VIDEOS setOpenShare={setOpenShare} />}
						/>
						<Route
							path="/contact"
							element={<ContactPage setOpenShare={setOpenShare} />}
						/>
						<Route path={geninfo[0].cv} />
						<Route path="/admin" element={<Admin />} />
						<Route
							path="/doc/privacy"
							element={<PrivacyPolicy />}
							rel="nofollow"
						/>
						<Route
							path="/doc/cookies"
							element={<CookiePolicy />}
							rel="nofollow"
						/>
						<Route path="*" element={<Navigate to="/" />} />
					</Routes>
				</LangContextProvider>
			</AuthContextProvider>
		</BrowserRouter>
	);
};
