import React, { useEffect, useContext } from 'react';

// Components
import { Header } from '../components/Header';
import { Contact } from '../components/Contact/Contact';
import { Footer } from '../components/Footer';

// Services
import LangContextProvider from '../context/LangContext';

export const ContactPage = ({ setOpenShare }) => {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	const { langText } = useContext(LangContextProvider);
	const text = langText.about;

	return (
		<div className="pages-about_container">
			<header style={{ backgroundColor: `black` }}>
				<Header active={'contact'} setOpenShare={setOpenShare} />
			</header>
			<main>
				<div className="components_about">
					<Contact />
				</div>
			</main>
			<footer className="components_footer-container">
				<Footer setOpenShare={setOpenShare} />
			</footer>
		</div>
	);
};
