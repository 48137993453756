import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

// Components
import { Modal } from '../components/Modal';

// Helpers
import { getLocalStoreValue } from '../helpers/LocalStore';

// Icons
import { ReactComponent as Close } from '../assets/icons/ui/close_nc.svg';
import { ReactComponent as ShareIcon } from '../assets/icons/ui/share_nc.svg';
import { ReactComponent as Certificate } from '../assets/icons/ui/certificate.svg';
import { ReactComponent as PDF } from '../assets/icons/ui/pdf.svg';
import { ReactComponent as Facebook } from '../assets/icons/social/facebook_full_nc.svg';
import { ReactComponent as Instagram } from '../assets/icons/social/instagram_full_circle_nc.svg';
// import { ReactComponent as Whatsapp } from '../assets/icons/social/whatsapp_nc.svg';
// import { ReactComponent as Twitter } from '../assets/icons/social/twitter_full_circle_nc.svg';

// Services
import LangContextProvider from '../context/LangContext';

// Read JSON Text data
const textEN = require('../data/lang-EN.json');
const textSP = require('../data/lang-ES.json');
const textFR = require('../data/lang-FR.json');

export const Menu = ({ setOpenMenu, setOpenShare, active }) => {
	const [auth, setAuth] = useState(false);

	// Service Context
	const { generalInfo, langText, setLangText } =
		useContext(LangContextProvider);
	const text = langText.menu;
	const userLang = langText.Language;
	// console.log(userLang);

	const handleenglish = () => {
		setLangText(textEN);
		setOpenMenu(false);
	};

	const handlespanish = () => {
		setLangText(textSP);
		setOpenMenu(false);
	};

	const handlefrench = () => {
		setLangText(textFR);
		setOpenMenu(false);
	};

	const handleShare = () => {
		setOpenMenu(false);
		setOpenShare(true);
	};

	useEffect(() => {
		// Helper Local Storage
		const getUserId = getLocalStoreValue('auth-ID');
		if (getUserId) {
			setAuth(true);
		}
	}, [setAuth]);

	// useEffect(() => {
	// 	window.scrollTo({ top: 0, behavior: 'smooth' });
	// }, []);

	return (
		<>
			<Modal>
				<div className="components_menu">
					<span className="icon-close" onClick={() => setOpenMenu(false)}>
						<Close />
					</span>
					<h2 className="menu-title">MENU</h2>
					<div className="links-container">
						<Link
							to="/"
							style={{ textDecoration: 'none' }}
							onClick={() => setOpenMenu(false)}
						>
							<span className={active === 'home' ? 'active' : 'link'}>
								{text[0].Home}
							</span>
						</Link>

						<Link
							to="/about"
							style={{ textDecoration: 'none' }}
							onClick={() => setOpenMenu(false)}
						>
							<span className={active === 'about' ? 'active' : 'link'}>
								{text[0].About}
							</span>
						</Link>

						<Link
							to="/exhibitions"
							style={{ textDecoration: 'none' }}
							onClick={() => setOpenMenu(false)}
						>
							<span className={active === 'exhibitions' ? 'active' : 'link'}>
								{text[0].Exhibitions}
							</span>
						</Link>

						<Link
							to="/works"
							style={{ textDecoration: 'none' }}
							onClick={() => setOpenMenu(false)}
						>
							<span className={active === 'works' ? 'active' : 'link'}>
								{text[0].Works}
							</span>
						</Link>

						<Link
							to="/videos"
							style={{ textDecoration: 'none' }}
							onClick={() => setOpenMenu(false)}
						>
							<span className={active === 'videos' ? 'active' : 'link'}>
								{text[0].Videos}
							</span>
						</Link>

						{/* <Link
							to='/nft'
							style={{ textDecoration: 'none' }}
							onClick={() => setOpenMenu(false)}
						>
							<span className={active === 'nft' ? 'active' : 'link'}>
								{text[0].NFT}
							</span>
						</Link> */}

						<Link
							to="/contact"
							style={{ textDecoration: 'none' }}
							onClick={() => setOpenMenu(false)}
						>
							<span className={active === 'contact' ? 'active' : 'link'}>
								{text[0].Contact}
							</span>
						</Link>

						{auth && (
							<Link
								to="/admin"
								style={{ textDecoration: 'none' }}
								onClick={() => setOpenMenu(false)}
							>
								<span className={active === 'admin' ? 'active' : 'link'}>
									{text[0].Admin}
								</span>
							</Link>
						)}
					</div>
					<div className="links-container-policy">
						<Link to="/doc/cookies" style={{ textDecoration: 'none' }}>
							<span className={active === 'cookies' ? 'active' : 'link'}>
								{text[0].Cookies_Policy}
							</span>
						</Link>
						<Link to="/doc/privacy" style={{ textDecoration: 'none' }}>
							<span className={active === 'privacy' ? 'active' : 'link'}>
								{text[0].Privacy_Policy}
							</span>
						</Link>
					</div>
					<div className="language-container">
						<p>
							{text[0].Language}
							<span>:</span>
						</p>
						<button
							className="btn-language"
							onClick={handleenglish}
							style={
								userLang === 'English'
									? { fontWeight: 'bold', textDecoration: 'underline' }
									: {}
							}
						>
							EN
						</button>
						<button
							className="btn-language"
							onClick={handlespanish}
							style={
								userLang === 'Spanish'
									? { fontWeight: 'bold', textDecoration: 'underline' }
									: {}
							}
						>
							ES
						</button>
						<button
							className="btn-language"
							onClick={handlefrench}
							style={
								userLang === 'French'
									? { fontWeight: 'bold', textDecoration: 'underline' }
									: {}
							}
						>
							FR
						</button>
					</div>

					<div className="icons-container">
						<button className="icon-share" onClick={handleShare}>
							<ShareIcon />
						</button>
						<a
							className="icons-social icon-instagram"
							href={generalInfo[0].instagram}
							target="_blank"
							rel="noreferrer"
						>
							<Instagram />
						</a>

						{/* <a
							className='icons-social'
							href={generalInfo[0].whatsapp}
							target='_blank'
							rel='noreferrer'
						>
							<Whatsapp />
						</a> */}

						<a
							className="icons-social icon-facebook"
							href={generalInfo[0].facebook}
							target="_blank"
							rel="noreferrer"
						>
							<Facebook />
						</a>
						<a
							className="icons-social"
							href="assets/pdf/Luxemburgo2022.pdf"
							target="_blank"
						>
							<Certificate />
						</a>
						<a href="assets/pdf/BrochureGA2022.pdf" target="_blank">
							CV
						</a>
						<a
							className="icons-social"
							href="assets/pdf/Dossier_Gissel_2010-2022-ENG.pdf"
							target="_blank"
						>
							<PDF />
						</a>
					</div>
				</div>
			</Modal>
		</>
	);
};
