import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

// Helpers
import { year } from '../helpers/Date';
import { getLocalStoreValue } from '../helpers/LocalStore';
import { clearLocalStoreValue } from '../helpers/LocalStore';

// Modals
import { Modal } from '../components/Modal';
import LoginForm from '../components/LoginForm';

// Icons
import { ReactComponent as ShareIcon } from '../assets/icons/ui/share_nc.svg';
import { ReactComponent as IconFacebook } from '../assets/icons/social/facebook_full_nc.svg';
import { ReactComponent as IconInstagram } from '../assets/icons/social/instagram_nc.svg';
import { ReactComponent as IconEmail } from '../assets/icons/social/email_nc.svg';
import { ReactComponent as Certificate } from '../assets/icons/ui/certificate.svg';
import { ReactComponent as PDF } from '../assets/icons/ui/pdf.svg';
// import { ReactComponent as Whatsapp } from '../assets/icons/social/whatsapp_nc.svg';

// Services
import AuthContextProvider from '../context/AuthContext';
import LangContextProvider from '../context/LangContext';

// Read JSON Data
const geninfo = require('../data/geninfo.json');
const geninfodata = geninfo.data;

export const Footer = ({ setOpenShare }) => {
	const [auth, setAuth] = useState(false);
	const [openLogin, setOpenLogin] = useState(false);

	// Services Context
	const {
		// user,
		setUser,
		setLiked,
		setAvatar,
		setUserAdmin,
		// userAceptCookies,
		setUserAcceptCookies,
	} = useContext(AuthContextProvider);

	const { langText } = useContext(LangContextProvider);
	const text = langText.footer;
	// console.log(text);

	const handleShare = () => {
		setOpenShare(true);
	};

	const handleLogin = () => {
		setOpenLogin(true);
		window.scrollTo({ top: 0 });
	};

	const handleLogout = () => {
		setAuth(false);
		clearLocalStoreValue('auth-ID');
	};

	useEffect(() => {
		// Helper Local Storage
		const getUserId = getLocalStoreValue('auth-ID');
		if (getUserId) {
			setUser(getUserId);
			setAuth(true);
			// setUserAcceptCookies(true);
			// console.log(user);
		}
	}, [setUser, setAuth]);

	return (
		<div className="components_footer" id="footer">
			{openLogin && (
				<Modal>
					<LoginForm
						setUser={setUser}
						setAvatar={setAvatar}
						setLiked={setLiked}
						setUserAdmin={setUserAdmin}
						setOpenLogin={setOpenLogin}
						setUserAcceptCookies={setUserAcceptCookies}
					/>
				</Modal>
			)}
			<div className="footer_icon-container">
				<button className="icon-share icon-share-footer" onClick={handleShare}>
					<ShareIcon />
				</button>
				<a
					className="icon-footer"
					href={geninfodata[0].facebook}
					target="_blank"
					rel="noopener noreferrer"
				>
					<IconFacebook />
				</a>
				<a
					className="icon-footer"
					href={geninfodata[0].instagram}
					target="_blank"
					rel="noopener noreferrer"
				>
					<IconInstagram />
				</a>

				{/* <a
					className='icon-footer'
					href={`mailto:${geninfodata[0].email}`}
					target='_blank'
					rel='noopener noreferrer'
				>
					<IconEmail />
				</a> */}
				<a
					className="icon-footer"
					href="assets/pdf/Luxemburgo2022.pdf"
					target="_blank"
				>
					<Certificate />
				</a>

				<a
					className="text-footer"
					href={geninfodata[0].cv}
					target="_blank"
					rel="noreferrer"
				>
					CV
				</a>
				<a
					className="icon-footer"
					href="assets/pdf/Dossier_Gissel_2010-2022-ENG.pdf"
					target="_blank"
				>
					<PDF />
				</a>
				{!auth ? (
					<p className="text-footer" onClick={handleLogin}>
						Login
					</p>
				) : (
					<p className="text-footer" onClick={handleLogout}>
						Logout
					</p>
				)}
			</div>
			<div className="copywrite">
				<div className="linked">
					<Link to="/doc/privacy" className="policy">
						{text[0].Privacy_Policy}
					</Link>

					<Link to="/doc/cookies" className="policy">
						{text[0].Cookies_Policy}
					</Link>
				</div>

				<p>
					<a
						href={geninfodata[0].webdesign}
						target="_blank"
						rel="noopener noreferrer"
					>
						Copyright and web design by © VMOG
					</a>
				</p>
				<p> Liverpool UK {year} </p>
			</div>
		</div>
	);
};
