import React, { useEffect, useContext } from 'react';

// Components
import { Header } from '../components/Header';
import { WorksComp } from '../components/WorksComp';
import { PageLink } from '../components/PageLink';
import { BackToTop } from '../components/BackToTop';
import { Footer } from '../components/Footer';

// Services
import LangContextProvider from '../context/LangContext';

export const Works = ({ setOpenShare }) => {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	const { langText } = useContext(LangContextProvider);
	const text = langText.works;

	return (
		<div className='pages_works-container'>
			<header style={{ backgroundColor: `black` }}>
				<Header active={'works'} setOpenShare={setOpenShare} />
			</header>
			<main>
				<WorksComp />
				<BackToTop />
				<div className='page-links'>
					<PageLink
						className='link'
						text={text[0].back_to}
						link={'/exhibitions'}
						left={true}
						right={false}
					/>
					<PageLink
						text={text[0].go_to}
						link={'/videos'}
						left={false}
						right={true}
					/>
				</div>
			</main>
			<footer className='components_footer-container'>
				<Footer setOpenShare={setOpenShare} />
			</footer>
		</div>
	);
};
