import React from 'react';

export const WorksText = ({ post }) => {
	const content = post.content;
	// console.log(post);
	// console.log(content);
	return (
		<>
			<h2>{post.year}</h2>

			{content &&
				content.map((item, index) => (
					<div key={`${index}-works`}>
						<p>{item.text}</p>
					</div>
				))}
		</>
	);
};
