import React, { useEffect, useState } from 'react';

// Components
import { Menu } from '../components/Menu';

// Icons
import { ReactComponent as MenuIcon } from '../assets/icons/ui/menu.svg';

// Read JSON Data
const geninfo = require('../data/geninfo.json').data;

export const Header = ({ setOpenShare, active }) => {
	// Open states
	const [openMenu, setOpenMenu] = useState(false);

	useEffect(() => {
		setOpenMenu(false);
	}, []);

	return (
		<>
			{openMenu && (
				<Menu
					setOpenMenu={setOpenMenu}
					setOpenShare={setOpenShare}
					active={active}
				/>
			)}

			<h3>
				<a
					className="logo"
					href={geninfo[0].webpage}
					target="_blank"
					rel="noreferrer"
				>
					<i>Gisseline Amiuny</i>
				</a>
			</h3>

			<button className="icon-menu" onClick={() => setOpenMenu(true)}>
				<span className="icon-menu">
					<MenuIcon />
				</span>
			</button>
		</>
	);
};
