import React, { useContext } from 'react';

// Services
import LangContextProvider from '../context/LangContext';

export const ExhibitionsComp = () => {
	const { langText } = useContext(LangContextProvider);
	const text = langText.exhibitions;
	const expo = text[0].expo;
	// console.log(text);
	// console.log(expo);

	return (
		<section>
			<span id='exhibitions' className='exhibitions-title'>
				<h2>{text[0].title}</h2>
				<h3>
					Caracas, Maracaibo, Bogota, Medellín, Lima, Miami, New York, Houston,
					Dallas, Barcelona Spain, El Salvador, Brussels, Brighton UK, Liverpool
					UK, París...
				</h3>
			</span>
			<div
				className='components_exhibitions-container'
				// data-aos='zoom-in'
				// data-aos-duration='2000'
			>
				{expo &&
					expo.map((expo, index) => (
						<div key={`${index}-expo`}>
							<p>
								<b>{expo.title}</b> {expo.text}
							</p>
						</div>
					))}
			</div>
		</section>
	);
};
