import React, { useEffect, useContext } from 'react';

// Components
import { Header } from '../components/Header';
import { AboutComp } from '../components/AboutComp';
import { BackToTop } from '../components/BackToTop';
import { PageLink } from '../components/PageLink';
import { Footer } from '../components/Footer';

// Services
import LangContextProvider from '../context/LangContext';

export const About = ({ setOpenShare }) => {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	const { langText } = useContext(LangContextProvider);
	const text = langText.about;

	return (
		<div className="pages-about_container">
			<header style={{ backgroundColor: `black` }}>
				<Header active={'about'} setOpenShare={setOpenShare} />
			</header>
			<main>
				<div className="components_about">
					<AboutComp />
				</div>
				<BackToTop />
				<div className="page-links">
					<PageLink
						className="link"
						text={text[0].back_to}
						link={'/'}
						left={true}
						right={false}
					/>
					<PageLink
						text={text[0].go_to}
						link={'/exhibitions'}
						left={false}
						right={true}
					/>
				</div>
			</main>
			<footer className="components_footer-container">
				<Footer setOpenShare={setOpenShare} />
			</footer>
		</div>
	);
};
