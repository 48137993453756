import React, { useState, useEffect } from 'react';

// Hooks
import { useDevice } from '../hooks/useDevice';

const Context = React.createContext({});

// Read JSON Data
const geninfo = require('../data/geninfo.json').data;
const text = require('../data/lang-EN.json');

export function LangContextProvider({ children }) {
	const [langText, setLangText] = useState(text);
	const [generalInfo, setGeneralInfo] = useState(geninfo);

	const { userLang } = useDevice();

	useEffect(() => {
		if (userLang === 'spanish') {
			const data = require('../data/lang-ES.json');
			setLangText(data);
		} else if (userLang === 'french') {
			const data = require('../data/lang-FR.json');
			setLangText(data);
		}
		// console.log(userLang);
	}, [userLang]);

	return (
		<Context.Provider
			value={{
				langText,
				setLangText,
				generalInfo,
				setGeneralInfo,
			}}
		>
			{children}
		</Context.Provider>
	);
}

export default Context;
