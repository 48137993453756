import React, { useContext } from 'react';

// Services
import LangContextProvider from '../context/LangContext';

// Components
import { WorksText } from '../components/WorksText';
import { WorksLinks } from '../components/WorksLinks';

export const WorksComp = () => {
	const { langText } = useContext(LangContextProvider);
	const content = langText.works;
	const posts = content[0].posts;
	// console.table(content);
	// console.table(content[0].posts);
	// console.log(posts);

	return (
		<section>
			<span id='works' className='works-title'>
				<h2>{content[0].title}</h2>
			</span>
			<div className='component_works-container'>
				{content[0].posts.map((post, index) => (
					<div
						className='works-post'
						key={index}
						// data-aos='zoom-in'
						// data-aos-duration='2000'
					>
						<div className='works-post-container'>
							{post.year && (
								<div className='works-text'>
									<div className='text'>
										<WorksText post={post} />
										<WorksLinks post={post} content={content} />
									</div>
								</div>
							)}
							{post.img && (
								<div className='works-img'>
									<img src={post.img} alt='gisselineamiunyart' />
								</div>
							)}
						</div>
					</div>
				))}
			</div>
		</section>
	);
};
