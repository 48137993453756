import React, { useContext } from 'react';

// Services
import LangContextProvider from '../context/LangContext';

// Icons
import { ReactComponent as IconArrow } from '../assets/icons/ui/arrow_up_icon.svg';

export const BackToTop = () => {
	const { langText } = useContext(LangContextProvider);
	const text = langText.home;

	return (
		<div className='buttons_container'>
			<a className='btn' href='#top'>
				<span className='icon icon-back'>
					<IconArrow />
				</span>
				{text[0].back_top}
			</a>
		</div>
	);
};
