import React, { useEffect, useContext } from 'react';

// Components
import { Header } from '../components/Header';
import { NFTcomp } from '../components/NFTcomp';
import { PageLink } from '../components/PageLink';
import { BackToTop } from '../components/BackToTop';
import { Footer } from '../components/Footer';

// Services
import LangContextProvider from '../context/LangContext';

export const NFT = ({ setOpenShare }) => {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	const { langText } = useContext(LangContextProvider);
	const text = langText.nft;

	return (
		<div className='pages_nft-container'>
			<header style={{ backgroundColor: `black` }}>
				<Header active={'nft'} setOpenShare={setOpenShare} />
			</header>

			<main>
				<h2>NFT</h2>
				<div className='intro'>
					<h1>{text[0].intro_h1}</h1>
					<h2>{text[0].intro_h2}</h2>
					<h3>{text[0].intro_h3}</h3>
				</div>
				<NFTcomp />
			</main>
			{/* <PageLink text={text[0].go_to} link={'/'} /> */}
			<BackToTop />
			<footer className='components_footer-container'>
				<Footer setOpenShare={setOpenShare} />
			</footer>
		</div>
	);
};
