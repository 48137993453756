import React, { useEffect, useContext } from 'react';

// Library
import ReactPlayer from 'react-player/lazy';

// Components
import { Header } from '../components/Header';
import { PageLink } from '../components/PageLink';
import { BackToTop } from '../components/BackToTop';
import { Footer } from '../components/Footer';

// Services
import LangContextProvider from '../context/LangContext';

export const VIDEOS = ({ setOpenShare }) => {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	const { langText } = useContext(LangContextProvider);
	const content = langText.videos;

	return (
		<div className='pages_videos-container'>
			<header style={{ backgroundColor: `black` }}>
				<Header active={'nft'} setOpenShare={setOpenShare} />
			</header>

			<main>
				<h1>{content[0].title}</h1>
				<h3>{content[0].video1}</h3>
				<div className='player-wrapper'>
					<ReactPlayer
						className='react-player'
						url={content[0].link1}
						config={{
							youtube: {
								playerVars: { showinfo: 1 },
							},
						}}
						width='100%'
						height='100%'
						playing
						loop
						muted
						// controls
					/>
				</div>

				<h3>{content[0].video2}</h3>
				<div className='player-wrapper'>
					<ReactPlayer
						className='react-player'
						url={content[0].link2}
						config={{
							youtube: {
								playerVars: { showinfo: 1 },
							},
						}}
						width='100%'
						height='100%'
						playing
						loop
						muted
						// controls
					/>
				</div>

				<h3>{content[0].video3}</h3>
				<div className='player-wrapper'>
					<ReactPlayer
						className='react-player'
						url={content[0].link3}
						config={{
							youtube: {
								playerVars: { showinfo: 1 },
							},
						}}
						width='100%'
						height='100%'
						playing
						loop
						muted
						// controls
					/>
				</div>

				<h3>{content[0].video4}</h3>
				<div className='player-wrapper'>
					<ReactPlayer
						className='react-player'
						url={content[0].link4}
						config={{
							youtube: {
								playerVars: { showinfo: 1 },
							},
						}}
						width='100%'
						height='100%'
						playing
						loop
						muted
						// controls
					/>
				</div>

				<h3>{content[0].video5}</h3>
				<div className='player-wrapper-sound'>
					<ReactPlayer
						className='react-player'
						url={content[0].link5}
						config={{
							youtube: {
								playerVars: { showinfo: 1 },
							},
						}}
						width='100%'
						height='100%'
						playing
						loop
						muted
						controls
					/>
				</div>

				<h3>{content[0].video6}</h3>
				<div className='player-wrapper-sound'>
					<ReactPlayer
						className='react-player'
						url={content[0].link6}
						width='100%'
						height='100%'
						playing
						loop
						muted
						controls
					/>
				</div>

				<h3>{content[0].video7}</h3>
				<div className='player-wrapper-sound'>
					<ReactPlayer
						className='react-player'
						url={content[0].link7}
						config={{
							youtube: {
								playerVars: { showinfo: 1 },
							},
						}}
						width='100%'
						height='100%'
						playing
						loop
						muted
						controls
					/>
				</div>

				<h3>{content[0].video8}</h3>
				<div className='player-wrapper-sound'>
					<ReactPlayer
						className='react-player'
						url={content[0].link8}
						config={{
							youtube: {
								playerVars: { showinfo: 1 },
							},
						}}
						width='100%'
						height='100%'
						playing
						loop
						muted
						controls
					/>
				</div>

				<h3>{content[0].video9}</h3>
				<div className='player-wrapper-sound'>
					<ReactPlayer
						className='react-player'
						url={content[0].link9}
						config={{
							youtube: {
								playerVars: { showinfo: 1 },
							},
						}}
						width='100%'
						height='100%'
						playing
						loop
						muted
						controls
					/>
				</div>
			</main>
			{/* <PageLink text={text[0].go_to} link={'/'} /> */}
			<BackToTop />
			<div className='page-links'>
				<PageLink
					className='link'
					text={content[0].back_to}
					link={'/works'}
					left={true}
					right={false}
				/>
				<PageLink
					text={content[0].go_to}
					link={'/home'}
					left={false}
					right={true}
				/>
			</div>
			<footer className='components_footer-container'>
				<Footer setOpenShare={setOpenShare} />
			</footer>
		</div>
	);
};
