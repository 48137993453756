import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

// Pages
import { Home } from '../pages/Home';

// Modals
import { Modal } from '../components/Modal';
import RegisterForm from '../components/RegisterForm';

// Hooks
import { useDevice } from '../hooks/useDevice';

// Helpers
import { getLocalStoreValue } from '../helpers/LocalStore';

// Icons
import { ReactComponent as HomeIcon } from '../assets/icons/ui/home_nc.svg';

// Services
import AuthContextProvider from '../context/AuthContext';

export const Admin = () => {
	const [auth, setAuth] = useState(false);

	// Open states
	const [openRegisterForm, setOpenRegisterForm] = useState(false);

	const { width, height, device, lang, mode, userLang } = useDevice();

	// Services Context
	const { user, setUser, setUserAcceptCookies } =
		useContext(AuthContextProvider);

	const handleRegister = () => {
		setOpenRegisterForm(true);
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	useEffect(() => {
		// Helper Local Storage
		const getUserId = getLocalStoreValue('auth-ID');
		if (getUserId) {
			setAuth(true);
		}
	}, [setAuth]);

	if (!user) {
		return <Navigate to='/' element={<Home />} />;
	}

	return (
		<div className='pages-container'>
			<header>
				<Link to='/' style={{ textDecoration: 'none' }}>
					<span className='icon-policy'>
						<HomeIcon />
					</span>
				</Link>
			</header>
			<main>
				<h1 className='page-title'>DASHBOARD</h1>
				{openRegisterForm && (
					<Modal>
						<RegisterForm
							setUser={setUser}
							setOpenRegisterForm={setOpenRegisterForm}
							setUserAcceptCookies={setUserAcceptCookies}
						/>
					</Modal>
				)}
				<div className='user-device'>
					<h2>USER-DEVICE:</h2>

					<h4>
						Auth: <b>{`${auth}`}</b>
					</h4>
					<h4>
						Width: <b>{`${width} px`}</b>
					</h4>
					<h4>
						Height: <b>{`${height} px`}</b>
					</h4>
					<h4>
						Device: <b>{`${device}`}</b>
					</h4>
					<h4>
						Lang: <b>{`${lang}`}</b>
					</h4>
					<h4>
						UserLang: <b>{`${userLang}`}</b>
					</h4>
					<h4>
						DarkMode: <b>{`${mode}`}</b>
					</h4>
				</div>
				<button className='btn btn-admin' onClick={handleRegister}>
					USER Register
				</button>
			</main>
			<footer className='components_footer-container'>Footer</footer>
		</div>
	);
};
