import { useEffect, useState } from 'react';

// Hooks
import { useDevice } from '../hooks/useDevice';

export const useScroll = ({ start }) => {
	const [navBackground, setNavBackground] = useState(false);

	const { height } = useDevice();

	// NAVBAR Background Change:
	const changeNavbarBackground = () => {
		// console.log(Math.round(window.scrollY));
		if (Math.round(window.scrollY) >= height - start) {
			setNavBackground(true);
		} else {
			setNavBackground(false);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', changeNavbarBackground);
		return () => {
			window.removeEventListener('scroll', changeNavbarBackground);
		};
		// eslint-disable-next-line
	}, []);
	return { navBackground };
};
