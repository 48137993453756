import React, { useContext } from 'react';

// Services
import LangContextProvider from '../context/LangContext';

export const AboutComp = () => {
	const { langText } = useContext(LangContextProvider);
	const text = langText.about;
	const content = text[0].text;
	const links = text[0].info;
	// console.log(text);
	// console.log(content);

	return (
		<section>
			<span id="about" className="about-title">
				<h2>{text[0].title}</h2>
				<h3>
					<i>{text[0].subtitle}</i>
				</h3>
			</span>
			<div className="component_about-container">
				<div className="about-img">
					<img src="../assets/img/gissel.jpg" alt="gisselineamiunyart" />
				</div>
				<div
					className="about-content"
					// data-aos='zoom-in'
					// data-aos-duration='2000'
				>
					{content &&
						content.map((text, index) => (
							<div key={`${index}-text`}>
								<p>
									{text.textp} <span>{text.textpb}</span>
								</p>
							</div>
						))}
				</div>
				<div
					className="about_links-container"
					// data-aos='zoom-in'
					// data-aos-duration='2000'
				>
					<h3 style={{ marginLeft: '1em', marginTop: '2.5em' }}>
						{text[0].texth3}
					</h3>
					<div className="links">
						{links &&
							links.map((info, index) => (
								<div key={`${index}-info`}>
									<a
										href={info.link}
										className="about-link"
										target="_blank"
										rel="noopener noreferrer"
									>
										{info.textl}
									</a>
									<br />
									<br />
								</div>
							))}
					</div>
				</div>
			</div>
			<img src="../assets/certificate/Luxemburgo2022.jpg" />
		</section>
	);
};
