import React, { useEffect } from 'react';
import { AppRouter } from './routers/AppRouter';

// AOS Library
import Aos from 'aos';
import 'aos/dist/aos.css';

// Hooks
// import { useDevice } from './hooks/useDevice';
// import { useLang } from './hooks/useLang';

export const App = () => {
	// const { width, height, device, lang, mode, userLang } = useDevice();
	// const { text } = useLang('en');

	useEffect(() => {
		Aos.init({
			delay: 300,
		});
		Aos.refresh();
	}, []);

	return <AppRouter />;
};
