import React from 'react';
import { Link } from 'react-router-dom';

// Icons
import { ReactComponent as ArrowRight } from '../assets/icons/ui/arrow_next_right.svg';
import { ReactComponent as ArrowLeft } from '../assets/icons/ui/arrow_back_left.svg';

export const PageLink = ({ link, text, left, right }) => {
	return (
		<div className='buttons_container'>
			<Link
				to={link}
				style={{ textDecoration: 'none', marginBottom: '2em' }}
				className='btn'
			>
				<span className='icon icon-link'>
					{left && <ArrowLeft />}
					<b style={{ margin: '0 1em' }}>{text}</b>
					{right && <ArrowRight />}
				</span>
			</Link>
		</div>
	);
};
