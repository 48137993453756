import React, { useEffect, useContext } from 'react';

// Components
import { Header } from '../components/Header';
import { ExhibitionsComp } from '../components/ExhibitionsComp';
import { PageLink } from '../components/PageLink';
import { BackToTop } from '../components/BackToTop';
import { Footer } from '../components/Footer';

// Services
import LangContextProvider from '../context/LangContext';

export const Exhibitions = ({ setOpenShare }) => {
	useEffect(() => {
		window.location.href = '#top';
	}, []);

	const { langText } = useContext(LangContextProvider);
	const text = langText.exhibitions;

	return (
		<div className='pages_exhibitions-container'>
			<header style={{ backgroundColor: `black` }}>
				<Header active={'exhibitions'} setOpenShare={setOpenShare} />
			</header>
			<span id='top'></span>
			<main>
				<div className='components_exhibitions'>
					<ExhibitionsComp />
				</div>

				<BackToTop />
				<div className='page-links'>
					<PageLink
						className='link'
						text={text[0].back_to}
						link={'/about'}
						left={true}
						right={false}
					/>
					<PageLink
						text={text[0].go_to}
						link={'/works'}
						left={false}
						right={true}
					/>
				</div>
			</main>
			<footer className='components_footer-container'>
				<Footer setOpenShare={setOpenShare} />
			</footer>
		</div>
	);
};
