import Axios from 'axios';

// helpers
import { setLocalStoreValue } from '../helpers/LocalStore';

const URL = process.env.REACT_APP_URL;

// USERS
export const PostUser = async ({ values, handleData, setserverError }) => {
	const config = {
		header: {
			'Content-Type': 'application/json',
		},
	};

	try {
		const data = await Axios.post(
			`${URL}/api/user/register`,
			{
				username: values.name,
				nickname: values.nickname,
				email: values.email,
				photo: values.photo,
				password: values.password,
				subscribe: values.subscribe,
			},
			config
		);

		// console.log(data.data);
		setLocalStoreValue('auth-ID', data.data.user);
		return handleData(data);
	} catch (error) {
		console.log(error.response.data.error);
		setserverError(error.response.data.error);
		setTimeout(() => {
			setserverError('');
		}, 5000);
	}
};

// LOGIN
export const PostLogin = async ({ values, handleData, setserverError }) => {
	const config = {
		header: {
			'Content-Type': 'application/json',
		},
	};

	try {
		const data = await Axios.post(
			`${URL}/api/user/login`,
			{
				email: values.email,
				password: values.password,
			},
			config
		);

		// console.log(data.data);
		setLocalStoreValue('auth-ID', data.data.user);
		return handleData(data);
	} catch (error) {
		console.log(error.response.data.error);
		setserverError(error.response.data.error);
		setTimeout(() => {
			setserverError('');
		}, 5000);
	}
};
