import React from 'react';

export const WorksLinks = ({ post, content }) => {
	const links = post.links;
	// console.log(links);

	return (
		<>
			{links && <h3>{content[0].linkName}</h3>}

			{links &&
				links.map((link, index) => (
					<div key={`${index}-link`}>
						<a href={link.link} target='_blank' rel='noopener noreferrer'>
							- {link.text}
						</a>
					</div>
				))}
		</>
	);
};
