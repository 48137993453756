import React, { useEffect, useContext } from 'react';

// Library
import ReactPlayer from 'react-player/lazy';

// Components
import { Header } from '../components/Header';
import { AboutComp } from '../components/AboutComp';
import { ExhibitionsComp } from '../components/ExhibitionsComp';
import { PageLink } from '../components/PageLink';
import { Contact } from '../components/Contact/Contact';
import { BackToTop } from '../components/BackToTop';
import { Footer } from '../components/Footer';

// Hooks
import { useScroll } from '../hooks/useScroll';

// Services
import LangContextProvider from '../context/LangContext';

export const Home = ({ setOpenShare }) => {
	// Check scrool for start new background 120px before the viewport height
	const start = 120;
	const { navBackground } = useScroll({ start });

	const { langText } = useContext(LangContextProvider);
	const text = langText.home;
	// console.log(text);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	return (
		<div className="pages-home_container">
			<header
				style={
					navBackground
						? {
								backgroundColor: `black`,
								boxShadow:
									'inset 0px -2px 3px rgba(255, 255, 255, 0.5), inset 0px 3px 3px rgba(255, 255, 255, 0.5)',
						  }
						: {}
				}
			>
				<Header active={'home'} setOpenShare={setOpenShare} />
			</header>

			<main>
				<div className="home-banner-container">
					<div
						className="home-banner"
						style={{ backgroundImage: `url(../assets/img/night.jpg)` }}
						// data-aos='zoom-in'
						// data-aos-duration='2000'
					>
						<h3>{text[0].Landing}</h3>
					</div>
				</div>

				<div className="componets_about">
					<AboutComp />
				</div>

				<div className="components_exhibitions">
					<ExhibitionsComp />
				</div>

				<h3>GEOMETRIC ENERGY, 28x28x28cm, 2017</h3>
				<div className="player-wrapper">
					<ReactPlayer
						className="react-player"
						url="https://www.youtube.com/watch?v=esLAn1u8xmU"
						config={{
							youtube: {
								playerVars: { showinfo: 1 },
							},
						}}
						width="100%"
						height="100%"
						playing
						loop
						muted
						// controls
					/>
				</div>
				<Contact />

				<BackToTop />
				<br />
				<PageLink
					text={text[0].go_to}
					link={'/works'}
					left={false}
					right={true}
				/>
			</main>
			<footer className="components_footer-container">
				<Footer setOpenShare={setOpenShare} />
			</footer>
		</div>
	);
};
