import React, { useContext } from 'react';

// Services
import LangContextProvider from '../context/LangContext';

export const NFTcomp = () => {
	const { langText } = useContext(LangContextProvider);
	const content = langText.nft;
	// console.table(content);
	// console.table(content[0].posts);

	return (
		<section>
			<span id='works' className='works-title'>
				<h2>{content[0].title}</h2>
			</span>
			<div className='component_works-container'>
				{/* {content[0].posts.map((post, index) => (
					<div className='works-post' key={index}>
						<div className='works-post-container'>
							{post.year && (
								<div className='works-text'>
									<div className='text'>
										<h2>{post.year}</h2>
										<p>{post.text}</p>
										<p>{post.text2}</p>
										<p>{post.text3}</p>
										<p>{post.text4}</p>
										<p>{post.text5}</p>
									</div>
								</div>
							)}
							{post.img && (
								<div className='works-img'>
									<img src={post.img} alt='gisselineamiunyart' />
								</div>
							)}
						</div>
					</div>
				))} */}
			</div>
		</section>
	);
};
